import { callApi } from '../utility/apiUtils';
import { client } from '../endpoints/client';

export const createClient = ({ body }) =>
  callApi({ uriEndPoint: client.createClient.v1, body })
    .then((res) => res)
    .catch((err) => {
      throw err;
    });

// this is for getting all clients on donor check-in page (public route!)
export const getAllClientsDonors = ({ body, query }) =>
  callApi({ uriEndPoint: client.getAllClientsDonors.v1, body, query });
export const getAllClients = ({ body, query }) => callApi({ uriEndPoint: client.getAllClients.v1, body, query });
export const expRandomCertListService = ({ body, query }) =>
  callApi({ uriEndPoint: client.expRandomCertList.v1, body, query });
export const driverTestCountService = ({ pathParams }) =>
  callApi({ uriEndPoint: client.driverTestCount.v1, pathParams });

export const updateClient = ({ body, pathParams }) =>
  callApi({ uriEndPoint: client.updateClient.v1, body, pathParams });
export const updateClientServices = ({ body, pathParams }) =>
  callApi({ uriEndPoint: client.updateClientService.v1, body, pathParams });
export const uploadClientContract = ({ body, pathParams }) =>
  callApi({ uriEndPoint: client.uploadClientContract.v1, body, pathParams });
export const updateClientPricing = ({ body, pathParams }) =>
  callApi({ uriEndPoint: client.updateClientPricing.v1, body, pathParams });

export const captureSignature = ({ body, pathParams }) =>
  callApi({ uriEndPoint: client.captureSignature.v1, body, pathParams });

export const getSingleClientDetails = ({ pathParams }) =>
  callApi({ uriEndPoint: client.getSingleClientDetails.v1, pathParams }).then((res) => {
    return res;
  });

export const getClientMisDetails = ({ pathParams, query }) =>
  callApi({ uriEndPoint: client.getClientMisDetails.v1, pathParams, query }).then((res) => {
    return res;
  });
export const deleteClientDetails = ({ pathParams }) =>
  callApi({ uriEndPoint: client.deleteClientDetails.v1, pathParams }).then((res) => {
    return res;
  });
export const updateClientStatus = ({ clientId, status }) =>
  callApi({ uriEndPoint: client.updateClientStatus.v1, pathParams: { id: clientId }, body: { status } });

export const addNotes = ({ body }) => callApi({ uriEndPoint: client.addNotes.v1, body });

export const deleteNotes = ({ pathParams }) =>
  callApi({ uriEndPoint: client.deleteNotes.v1, pathParams }).then((res) => {
    return res;
  });

export const getAllCompanyNames = ({ body }) => callApi({ uriEndPoint: client.getAllCompanyNames.v1, body });
export const getDotDetails = ({ query }) => callApi({ uriEndPoint: client.getDotDetails.v1, query });
export const withNoPreEmployementTest = ({ query }) =>
  callApi({ uriEndPoint: client.withNoPreEmployementTest.v1, query });
export const updateRandomCertificate = ({ pathParams, body }) =>
  callApi({ uriEndPoint: client.updateRandomCertificate.v1, pathParams, body });
export const updateExpirationDateService = ({ pathParams, body }) =>
  callApi({ uriEndPoint: client.updateExpirationDate.v1, pathParams, body });
export const deleteDriversListService = ({ body }) => callApi({ uriEndPoint: client.deleteDriversList.v1, body });
