/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */

import { message, notification } from 'antd';
import moment from 'moment';
import React, { createContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  addClientToPoolService,
  addPool,
  deleteDriverFromPool,
  deletePoolService,
  getPool,
  getSinglePoolService,
  removeClientFromPoolService,
  updatePoolService,
} from '../services/pool';
import {
  createRandomDriverService,
  moveAlternateToSelectedDrivers,
  moveRandomToSelectedDrivers,
  removeSelectedDriver,
  sendNotification,
} from '../services/driver';
import { getAllClients } from '../services/client';

// ** Defaults
const defaultProvider = {
  poolDetails: {},
};
const PoolContext = createContext(defaultProvider);

const populateDriverInRandomBatAlternate = ({ restData, allDrivers, drivers, clients, selected, type }) => {
  return drivers
    ?.map((i) => {
      let props = {
        isAlternate: false,
        isSelected: false,
        ...restData,
      };
      if (type === 'random' || type === 'bat') {
        const foundRandomDriver = selected?.find((j) => j.driverId === i);
        if (foundRandomDriver) {
          // if the driver is selected then we will show the driver as selected as moved
          props = {
            isSelected: true,
            isAlternate: false,
          };
        } else {
          // if the driver is not selected then we will show the driver as alternated
          const isAlternate = selected?.find((j) => j.alternateForDriver === i);
          if (isAlternate) {
            props = {
              isSelected: false,
              isAlternate: true,
              alternateDriver: allDrivers?.find((j) => j.uuid === isAlternate?.driverId),
              alternateReason: isAlternate?.alternateReason,
            };
          }
        }
      }
      if (type === 'alternate') {
        const foundAlternate = selected?.find((j) => j.driverId === i && j?.isAlternate);
        if (foundAlternate) {
          props = {
            isSelected: true,
            isAlternate: true,
            alternateDriver: allDrivers?.find((j) => j.uuid === foundAlternate?.alternateForDriver),
            alternateReason: foundAlternate?.alternateReason,
          };
        }
      }
      const driver = allDrivers?.find((j) => j.uuid === i);
      return {
        driver,
        client: clients?.find((j) => j.uuid === driver?.clientId),
        ...props,
      };
    })
    ?.filter((i) => i?.driver);
};
const populateClientsInDriver = ({ drivers, clients }) => {
  return drivers?.map((i) => {
    return {
      ...i,
      driver: i,
      client: clients?.find((j) => j.uuid === i.clientId),
    };
  });
};

const populateDriversInSelected = ({ allDrivers, selected, clients }) => {
  return selected?.map((i) => {
    const response = {
      ...i,
      driver: allDrivers?.find((j) => j.uuid === i.driverId),
      alternateForDriver: i?.isAlternate ? allDrivers?.find((j) => j.uuid === i.alternateForDriver) : false,
      client: clients?.find((j) => j.uuid === i.clientId),
    };
    // temporary fix, because the client is not getting sent as the alternate driver's
    response.client = clients?.find((j) => j.uuid === response.driver.clientId);
    return response;
  });
};
function PoolProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [quarter, setQuarter] = useState(moment().format('YYYY-[Q]Q'));
  const [poolDetails, setPoolDetails] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [randomDriversInPool, setRandomDriversInPool] = useState([]);
  const [batDriversInPool, setBatDriversInPool] = useState([]);
  const [clients, setClients] = useState([]);
  const [alternateDriversInPool, setAlternateDriversInPool] = useState([]);
  const [selectedDriversInPool, setSelectedDriversInPool] = useState([]);
  const [clientsInPool, setClientsInPool] = useState([]);
  const [driversInPool, setDriversInPool] = useState([]);
  const [poolList, setPoolList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const pathName = useParams();
  const { '*': path } = pathName;
  const [activeTab, setActiveTab] = useState(path);
  const currentQuarter = moment().format('YYYY-[Q]Q');
  // const currentQuarter = '2024-Q4';
  const isCurrentQuarter = currentQuarter === quarter;

  const resetFields = () => {
    setPoolDetails({});
    setClientsInPool([]);
    setDriversInPool([]);
    setRandomDriversInPool([]);
    setBatDriversInPool([]);
    setAlternateDriversInPool([]);
    setSelectedDriversInPool([]);
  };

  const getPoolDetails = ({ id, quarter }) => {
    setLoading(true);
    getSinglePoolService({
      pathParams: { id },
      query: {
        quarter,
      },
    })
      .then((res) => {
        const restData = {
          poolId: res?.data?.poolId,
          poolName: res?.data?.poolName,
          user: res?.data?.user,
          type: res?.data?.type,
          quarter: res?.data?.quarter,
          poolQuarterId: res?.data?.poolQuarterId,
        };
        setPoolDetails(res?.data);
        setClientsInPool(res?.data?.clients);
        setDriversInPool(
          populateClientsInDriver({
            drivers: res?.data?.drivers,
            clients: res?.data?.clients,
          }),
        );
        setRandomDriversInPool(
          populateDriverInRandomBatAlternate({
            restData,
            allDrivers: res?.data?.drivers,
            drivers: res?.data?.randomDrivers,
            clients: res?.data?.clients,
            selected: res?.data?.selectedDrivers,
            type: 'random',
          }),
        );
        setBatDriversInPool(
          populateDriverInRandomBatAlternate({
            restData,
            allDrivers: res?.data?.drivers,
            drivers: res?.data?.batDrivers,
            clients: res?.data?.clients,
            selected: res?.data?.selectedDrivers,
            type: 'bat',
          }),
        );
        setAlternateDriversInPool(
          populateDriverInRandomBatAlternate({
            restData,
            allDrivers: res?.data?.drivers,
            drivers: res?.data?.alternateDrivers,
            clients: res?.data?.clients,
            selected: res?.data?.selectedDrivers,
            type: 'alternate',
          }),
        );
        setSelectedDriversInPool(
          populateDriversInSelected({
            restData,
            allDrivers: res?.data?.drivers,
            selected: res?.data?.selectedDrivers,
            clients: res?.data?.clients,
          }),
        );
        setLoading(false);
      })
      .finally(() => {});
  };

  const getPoolList = () => {
    setLoading(true);
    getPool({
      query: {},
    })
      .then((res) => {
        setPoolList(res);
      })
      .finally(() => {
        console.log(1, 'lol');
        setLoading(false);
      });
  };

  const fetchAllClients = () => {
    getAllClients({
      query: {
        startIndex: 0,
        viewSize: 1000,
        fetchClientsWithNoPools: true,
      },
    })
      .then((res) => {
        setClients([
          ...(res?.data || []).map((i) => ({
            value: i.uuid,
            label: i.motorCarrierName,
          })),
        ]);
      })
      .finally(() => {});
  };

  const updatePool = ({ type, poolId, poolName }, cb, errCb) => {
    setBtnLoading(true);
    updatePoolService({
      body: {
        poolName,
        type,
      },
      pathParams: {
        id: poolId,
      },
    })
      .then(() => {
        cb();
        getPoolList();
        notification.success({
          message: 'Success',
          description: 'Pool Updated Successfully',
        });
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: err?.data?.error?.message,
        });
        errCb();
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };
  const createPool = ({ poolName, type }, cb, errCb) => {
    setBtnLoading(true);
    addPool({
      body: {
        poolName,
        type,
      },
    })
      .then(() => {
        cb();
        setBtnLoading(false);
        getPoolList();
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: err?.data?.error?.message,
        });
        errCb();
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };
  const deletePool = ({ poolId }) => {
    deletePoolService({
      pathParams: {
        id: poolId,
      },
    })
      .then(() => {
        message.success('Pool Deleted Successfully', 1);
        setLoading(false);
        getPoolList();
      })
      .catch((err) => {
        message.error(err?.data?.error?.message, 1);
        setLoading(false);
      });
  };
  const deleteDriver = ({ driverId }) => {
    deleteDriverFromPool({ pathParams: { id: driverId } })
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'Driver Removed from Pool',
        });
        getPoolDetails();
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: err?.data?.error?.message,
        });
      });
  };

  const addClients = ({ poolId, clientList }, cb) => {
    setBtnLoading(true);
    addClientToPoolService({
      body: {
        clients: clientList || [],
      },
      pathParams: {
        poolId,
      },
    })
      .then(() => {
        getPoolDetails({
          id: poolId,
          quarter,
        });
        notification.success({
          message: 'Success',
          description: 'Client Added to Pool',
        });
        cb();
      })
      .catch(() => {
        // notification.error({
        //   description: err?.data?.error?.message,
        // });
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };
  const removeClientFromPool = ({ poolId, clientId }, cb, errCb) => {
    setDeleteLoading(true);
    removeClientFromPoolService({
      pathParams: {
        poolId,
        clientId,
      },
    })
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'Client Removed from Pool',
        });
        getPoolDetails({
          id: poolId,
          quarter,
        });
        cb();
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: err?.data?.error?.message,
        });
        errCb();
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  // create random drivers in pool
  const createRandomDrivers = ({ poolId }, cb, errCb) => {
    createRandomDriverService({
      pathParams: {
        poolId,
      },
    })
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'Random Drivers Created Successfully',
        });
        getPoolDetails({
          id: poolId,
          quarter,
        });
        cb();
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: err?.data?.error?.message,
        });
        errCb();
      })
      .finally(() => {});
  };
  const moveRandomDriverToSelected = ({ clientId, driverId, poolQuarterId, poolId, testType }, cb, errCb) => {
    moveRandomToSelectedDrivers({
      body: {
        clientId,
        driverId,
        poolQuarterId,
        testType,
      },
      pathParams: {
        poolId,
      },
    })
      .then(() => {
        getPoolDetails({
          id: poolId,
          quarter,
        });
        message.success('Driver moved to selected drivers successfully', 1);
        cb();
      })
      .catch((err) => {
        notification.error({ message: 'Error', description: err?.data?.error?.message });
        errCb();
      });
  };
  const deleteSelectedDriver = ({ randomDriverId, poolQuarterId, poolId }, cb, errCb) => {
    removeSelectedDriver({
      pathParams: {
        poolQuarterId,
        randomDriverId,
      },
    })
      .then(() => {
        getPoolDetails({
          id: poolId,
          quarter,
        });
        message.success('Driver deselected successfully', 1);
        if (cb) cb();
      })
      .catch((err) => {
        notification.error({ message: 'Error', description: err?.data?.error?.message });
        if (errCb) errCb();
      });
  };
  const moveAlternateDriverToSelected = (
    { clientId, driverId, poolQuarterId, poolId, alternateForDriver, alternateReason, testType },
    cb,
    errCb,
  ) => {
    moveAlternateToSelectedDrivers({
      body: {
        clientId,
        driverId,
        poolQuarterId,
        alternateForDriver,
        alternateReason,
        testType,
      },
      pathParams: {
        poolId,
      },
    })
      .then(async () => {
        getPoolDetails({
          id: poolId,
          quarter,
        });
        message.success('Driver moved to selected drivers successfully', 1);
        cb();
      })
      .catch((err) => {
        notification.error({ message: 'Error', description: err?.data?.error?.message });
        errCb();
      });
  };

  const sendNotificationTo = ({ id, poolId, driverId, sendEmailToDriver, clientData }, cb, errCb) => {
    sendNotification({
      body: {
        id,
        driverId,
        sendEmailToDriver,
        clientData,
      },
    })
      .then(() => {
        getPoolDetails({
          id: poolId,
          quarter,
        });
        message.success('Notification has been sent');
        cb();
      })
      .catch((err) => {
        notification.error({ message: 'Error', description: err?.data?.error?.message });
        errCb();
      });
  };

  const searchRes = (data, keyword, clientId) => {
    return data
      ?.filter((item) => (clientId ? item?.clientId === clientId : true))
      ?.filter((item) => {
        const firstName = item?.driver?.firstName?.toLowerCase();
        const lastName = item?.driver?.lastName?.toLowerCase();
        const licenseNumber = item?.driver?.licenseNumber?.toLowerCase();
        const lowerCaseKeyword = keyword.toLowerCase();
        if (keyword)
          return (
            firstName?.includes(lowerCaseKeyword) ||
            lastName?.includes(lowerCaseKeyword) ||
            (firstName && lastName && `${firstName} ${lastName}`.includes(lowerCaseKeyword)) ||
            licenseNumber?.includes(lowerCaseKeyword)
          );
        return true;
      });
  };
  const values = useMemo(
    () => ({
      searchRes,
      poolDetails,
      loading,
      activeTab,
      setActiveTab,
      getPoolDetails,
      deleteDriver,
      getPoolList,
      poolList,
      updatePool,
      createPool,
      deletePool,
      setPoolDetails,
      setLoading,
      addClients,
      clientsInPool,
      driversInPool,
      setBtnLoading,
      removeClientFromPool,
      setDeleteLoading,
      deleteLoading,
      setClientsInPool,
      setDriversInPool,
      createRandomDrivers,
      randomDriversInPool,
      alternateDriversInPool,
      moveRandomDriverToSelected,
      deleteSelectedDriver,
      selectedDriversInPool,
      moveAlternateDriverToSelected,
      sendNotificationTo,
      batDriversInPool,
      setQuarter,
      quarter,
      clients,
      fetchAllClients,
      resetFields,
      isCurrentQuarter,
      currentQuarter,
    }),
    [
      poolDetails,
      loading,
      activeTab,
      setActiveTab,
      getPoolDetails,
      deleteDriver,
      getPoolList,
      btnLoading,
      poolList,
      updatePool,
      createPool,
      deletePool,
      setPoolDetails,
      setLoading,
      addClients,
      clientsInPool,
      driversInPool,
      setBtnLoading,
      removeClientFromPool,
      setDeleteLoading,
      deleteLoading,
      setClientsInPool,
      setDriversInPool,
      createRandomDrivers,
      randomDriversInPool,
      alternateDriversInPool,
      moveRandomDriverToSelected,
      deleteSelectedDriver,
      selectedDriversInPool,
      moveAlternateDriverToSelected,
      sendNotificationTo,
      batDriversInPool,
      setQuarter,
      quarter,
      clients,
      fetchAllClients,
      resetFields,
      searchRes,
      isCurrentQuarter,
      currentQuarter,
    ],
  );
  return <PoolContext.Provider value={values}>{children}</PoolContext.Provider>;
}

export { PoolContext, PoolProvider };
