import { callApi } from '../utility/apiUtils';
import { driver } from '../endpoints/driver';

export const createDriver = ({ body }) => callApi({ uriEndPoint: driver.createDriver.v1, body });
export const createDriverBulk = ({ body }) => callApi({ uriEndPoint: driver.createDriverBulk.v1, body });

export const getAllDrivers = ({ query }) => callApi({ uriEndPoint: driver.getAllDrivers.v1, query });
export const preEmploymentDriversService = ({ query }) =>
  callApi({ uriEndPoint: driver.preEmploymentDrivers.v1, query });
export const getRandomDrivers = ({ query, pathParams }) =>
  callApi({ uriEndPoint: driver.getRandomDrivers.v1, query, pathParams });
export const getPoolRandomDriversService = ({ query, pathParams }) =>
  callApi({ uriEndPoint: driver.getPoolRandomDrivers.v1, query, pathParams });
export const getPoolBatDriversService = ({ query, pathParams }) =>
  callApi({ uriEndPoint: driver.getPoolBatDrivers.v1, query, pathParams });
export const getPoolAlternateDriversService = ({ query, pathParams }) =>
  callApi({ uriEndPoint: driver.getPoolAlternateDrivers.v1, query, pathParams });
export const getPoolAlternateDriversListService = ({ query, pathParams }) =>
  callApi({ uriEndPoint: driver.getPoolAlternateDriversList.v1, query, pathParams });
export const getPoolSelectedDriversService = ({ query, pathParams }) =>
  callApi({ uriEndPoint: driver.getPoolSelectedDrivers.v1, query, pathParams });
export const getAllSelectedDriversService = ({ query, pathParams }) =>
  callApi({ uriEndPoint: driver.getAllSelectedDrivers.v1, query, pathParams });

export const updateDriver = ({ body, pathParams }) =>
  callApi({ uriEndPoint: driver.updateDriver.v1, body, pathParams });

export const hireDriver = ({ body, pathParams }) => callApi({ uriEndPoint: driver.hireDriver.v1, body, pathParams });
export const updateDriverStage = ({ body, pathParams }) =>
  callApi({ uriEndPoint: driver.updateDriverStage.v1, body, pathParams });

export const updatePreEmploymentService = ({ body, pathParams }) =>
  callApi({ uriEndPoint: driver.updatePreEmployment.v1, body, pathParams });

export const updateDriverClient = ({ body, pathParams }) =>
  callApi({ uriEndPoint: driver.updateDriverClient.v1, body, pathParams });

export const updateDriverStatus = ({ body, pathParams }) =>
  callApi({ uriEndPoint: driver.updateDriverStatus.v1, body, pathParams });
// this api is fetch with lookup

export const getSingleDriverDetails = ({ query, pathParams }) =>
  callApi({ uriEndPoint: driver.getSingleDriverDetails.v1, query, pathParams }).then((res) => {
    return res;
  });
// this api is fetch without lookup
export const getDriverDetails = ({ query, pathParams }) =>
  callApi({ uriEndPoint: driver.getDriverDetails.v1, query, pathParams }).then((res) => {
    return res;
  });

export const deleteDriverDetails = ({ pathParams }) =>
  callApi({ uriEndPoint: driver.deleteDriverDetails.v1, pathParams }).then((res) => {
    return res;
  });

export const createImage = ({ body }) => callApi({ uriEndPoint: driver.createImage.v1, body });

export const addToRandomList = ({ body }) => callApi({ uriEndPoint: driver.addToRandomList.v1, body });
export const createRandomDriverService = ({ pathParams }) =>
  callApi({ uriEndPoint: driver.createRandomDrivers.v1, pathParams });
export const moveRandomToSelectedDrivers = ({ pathParams, body }) =>
  callApi({ uriEndPoint: driver.selectedDrivers.v1, pathParams, body });
export const removeSelectedDriver = ({ pathParams }) =>
  callApi({ uriEndPoint: driver.removeSelectedDriver.v1, pathParams });
export const moveAlternateToSelectedDrivers = ({ pathParams, body }) =>
  callApi({ uriEndPoint: driver.alternateDrivers.v1, pathParams, body });

export const removeFromRandomList = ({ body }) => callApi({ uriEndPoint: driver.removeFromRandomList.v1, body });

export const setQuarter = ({ body }) => callApi({ uriEndPoint: driver.setQuarter.v1, body });

export const createDriverDrugTest = ({ body }) => callApi({ uriEndPoint: driver.createDriverDrugTest.v1, body });
export const createTestCaseService = ({ body }) => callApi({ uriEndPoint: driver.createTestCase.v1, body });
export const updateTestCaseService = ({ body, pathParams }) =>
  callApi({ uriEndPoint: driver.updateTestCases.v1, body, pathParams });
export const uploadTestResultService = ({ body, pathParams }) =>
  callApi({ uriEndPoint: driver.uploadTestResult.v1, body, pathParams });

export const addFollowUpTest = ({ body }) => callApi({ uriEndPoint: driver.addFollowUpTest.v1, body });
export const uploadPreEmploymentResult = ({ body, pathParams }) =>
  callApi({ uriEndPoint: driver.uploadPreEmploymentResult.v1, body, pathParams });

export const addQuery = ({ body }) => callApi({ uriEndPoint: driver.addQuery.v1, body });
export const driverListStats = ({ query }) => callApi({ uriEndPoint: driver.driverListStats.v1, query });
export const uploadRandomResult = ({ pathParams, body }) =>
  callApi({ uriEndPoint: driver.uploadRandomResult.v1, pathParams, body });
export const uploadPreEmpTest = ({ pathParams, body }) =>
  callApi({ uriEndPoint: driver.uploadPreEmpTest.v1, pathParams, body });
export const sendNotification = ({ body }) => callApi({ uriEndPoint: driver.sendNotification.v1, body });
export const testResultService = ({ query }) => callApi({ uriEndPoint: driver.testResult.v1, query });
export const dashboardTestingListService = ({ query }) =>
  callApi({ uriEndPoint: driver.dashboardTestingList.v1, query });
export const deleteDrugTestService = ({ pathParams }) => callApi({ uriEndPoint: driver.deleteDrugTest.v1, pathParams });
export const updateTestResultService = ({ pathParams, body }) =>
  callApi({ uriEndPoint: driver.updateTestResult.v1, pathParams, body });
export const createNotesService = ({ body }) => callApi({ uriEndPoint: driver.createNotes.v1, body });
export const deleteNotesService = ({ pathParams }) => callApi({ uriEndPoint: driver.deleteNotes.v1, pathParams });
export const editNotesService = ({ pathParams, body }) =>
  callApi({ uriEndPoint: driver.editNotes.v1, pathParams, body });
export const deleteBulkDriversService = ({ body }) => callApi({ uriEndPoint: driver.deleteDriversList.v1, body });

export const getOrderTestCaseService = ({ query = {} }) =>
  callApi({ uriEndPoint: driver.getOrdersTestCaseList.v1, query });

export const deleteOrderTestCaseService = ({ pathParams }) =>
  callApi({ uriEndPoint: driver.deleteTestCaseOrder.v1, pathParams });
