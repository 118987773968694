import { useAtom } from 'jotai'; // Assuming you're using Jotai for state management
import { useSelector } from 'react-redux';
import { tenantData } from '../jotaiStore/tenants';

function useWholesaleTenant() {
  const [getTenant] = useAtom(tenantData);
  const isWholesale = getTenant?.data?.type === 'wholesale';

  const currentUser = useSelector((state) => state.currentUser.data);
  const role = currentUser?.tenantRoles?.[0]?.role?.toLowerCase()?.replace(' ', '');
  const isNotWholesaleSuperAdmin = !isWholesale ? true : role === 'superadmin';
  //   const isAdmin = role === 'admin';
  //   const isUser = role === 'user';

  return { isWholesale, isNotWholesaleSuperAdmin };
}

export default useWholesaleTenant;
